import React, { memo, useState } from 'react';

// Libraries
import { m } from "framer-motion";

const ScrollToTopButton = () => {

    const [visible, setVisible] = useState(false)

    const sendMensagemWhatsapp = () => {
        const textoPredefinido = encodeURIComponent('Olá! Gostaria de saber mais informações sobre os produtos/serviços oferecidos em seu site. Pode me ajudar?');
        window.open(`https://wa.me/5535997389755?text=${textoPredefinido}`, '_blank');
    };

    window.addEventListener('scroll', () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    });

    return (
        visible ? (
            <m.div className="scroll-top-arrow cursor-pointer bottom-[45px] right-[45px] top-auto z-[20] fixed" initial={{ opacity: 0, y: 50 }} animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} transition={{ duration: 0.5 }}>
                <div onClick={sendMensagemWhatsapp} className="rounded-full w-[50px] h-[50px] bg-[#00A884] shadow-[0_0_20px_rgba(23,23,23,0.25)] flex justify-center items-center hover:-translate-y-[2px] hover:shadow-[0_0_30px_rgba(23,23,23,0.40)] transition-default xs:w-[32px] xs:h-[32px] ">
                    <i className="fa-brands fa-whatsapp text-[25px] text-white xs:text-[18px]" />
                </div>
            </m.div>
        ) : (
            <></>
        )
    );
}

export default memo(ScrollToTopButton);
